@charset "UTF-8";

@import "../../templates/layout/font-variable";
@import "../../templates/ma-layout/ma-reset-mixin";
@import "../../atoms/colors";
@import "../../atoms/z-index";

#o-ma-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    // 背景
    .o-ma-modal-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 120%; //iPhone対策
        background-color: rgba(0, 0, 0, 0.6);
        z-index: $ma-modal-overlay-z-index;
    }
}

.o-ma-modal-main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: $base-text-color;
    font-family: $base-font-family;
    font-size: 13px;
    line-height: 1.5;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: $ma-modal-z-index;

    a {
        &:hover {
            opacity: 1;
            text-decoration: none;
            transition: none;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        background: none;
        border: none;
        border-radius: 0;
        margin: 0;
        outline: none;
        padding: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .o-ma-modal-main__inner {
        display: flex;
        max-width: 360px;
        max-height: 100%;
        height: auto;
        padding: 10px 0;
    }

    .o-ma-modal-noticePop {
        position: relative;
        height: auto;
        background-color: transparent;
        box-sizing: border-box;
        padding: 34px 20px 45px;

        // モーダルクローズボタン
        .o-ma-modal-noticePop__closeBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 10px;
            width: 44px;
            height: 44px;
            transition: none;
            z-index: $ma-modal-close;

            .ico {
                color: #fff;
                font-size: 30px;
            }

            &:hover {
                text-decoration: none;
                transition: none;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    // チェックボックス
    .o-ma-modal-check {
        position: absolute;
        left: 50%;
        min-width: 160px;
        bottom: 0;
        text-align: center;
        transform: translateX(-50%);

        .o-ma-modal-check__checkbox {
            display: inline-block;
            position: relative;
            width: 100%;
            height: 100%;
            cursor: pointer;

            input {
                display: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }

        .o-ma-modal-check__checkbox__label {
            display: inline-block;
            position: relative;
            color: #fff;
            line-height: 1.2;
            margin: 0;
            font-size: 13px;
            padding: 12px;
            user-select: none;
            vertical-align: middle;
            color-scheme: only light;

            &:before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: #fff;
                border: 1px solid #ccc;
                margin: 0 10px 0 0;
                text-align: center;
                vertical-align: middle;
            }

            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 15px;
                left: 18px;
                margin: -8px 0 0;
                transform: rotate(45deg);
            }
        }
    }

    .o-ma-modal-check__checkbox input:checked + span:after {
        width: 12px;
        height: 20px;
        border-bottom: 5px solid #27ce14;
        border-right: 4px solid #27ce14;
    }

    // 画像のみの場合
    .o-ma-modal-noticePop__imgArea {
        max-width: 320px;
        height: 100%;
        box-sizing: border-box;

        @media screen and (max-width: 340px) {
            max-width: 280px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: bottom;
        }

        a {
            display: inline-block;
            height: 100%;
        }
    }

    // idを使うと優先度が高くなるので分離 html+css入力欄
    .o-ma-modal-noticePop__htmlArea {
        max-width: 320px;
        width: 100%;
        max-height: 480px;
        height: 100%;
        overflow-y: auto;
        pointer-events: auto;
        word-wrap: break-word;
        overflow-wrap: break-word;

        @media screen and (max-width: 340px) {
            max-width: 280px;
        }

        // リセットとbase
        @include ma-reset;
    }
}

