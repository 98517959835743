/*
    MAツールを全世代で綺麗に表示出来るようにbaseを平均化するCSS
    MAの中身のコードは管理画面から入力されるため、あらかじめ世代ごとのクセを打ち消しておく
    基本的にMA以外では使わない（動的でない限り世代間のずれは各ページのCSSで対応出来る）想定
*/

@import "../../atoms/colors";
@import "../layout/font-variable";

@mixin ma-reset {
    color: $base-text-color;
    font-family: $base-font-family;
    line-height: 1.5;
    word-wrap: break-word;
    overflow-wrap: break-word;

    div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button {
        border: 0;
        font: inherit;
        font-size: 13px;
        margin: 0;
        padding: 0;
        text-align: left;
        vertical-align: baseline;
    }

    *:focus {
        outline: 0;
    }

    *,
    *::before,
    *::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-text-size-adjust: 100%;
    }

    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        appearance: none;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after, q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    img {
        max-width: 100%;
        vertical-align: bottom;
    }

    a {
        display: inline;
        color: $base-link-color;
        cursor: pointer;
        text-decoration: none;
        transition: none;

        &:link, &:visited, &:active, &:hover {
            color: $base-link-color;
        }

        &:hover {
            text-decoration: none;
            transition: none;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                opacity: 0.7;
            }
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        border-radius: 0;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
